import React, { Component } from 'react'
import shortid from 'shortid'
import { withStyles } from '@material-ui/styles'
import { GameForm } from '../forms'
import { Dashboard } from '../layouts'
import axios from '../plugins/axios'

const styles = theme => ({
    //
})

class GameUpdate extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: 'Game',
            key: shortid.generate(),
            name: '',
            cover: '',
            icon: '',
            mockup: '',
            app_store: '',
            play_store: '',
            description: '',
            is_hit: false
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        const { match } = this.props
        const { id } = match.params

        try {
            const { data } = await axios.get(`/games/${id}`)

            this.setState({
                key: shortid.generate(),
                name: data.name,
                cover: data.cover,
                icon: data.icon,
                mockup: data.mockup,
                app_store: data.app_store,
                play_store: data.play_store,
                description: data.description,
                is_hit: data.is_hit,
            })
        } catch (e) {
            //
        }
    }

    render() {
        const {
            title,
            key,
            name,
            cover,
            icon,
            mockup,
            app_store,
            play_store,
            description,
            is_hit
        } = this.state
        const { match } = this.props
        const { id } = match.params
        return (
            <Dashboard title={title}>
                <GameForm
                    key={key}
                    path={`games/${id}`}
                    method="put"
                    name={name}
                    cover={cover}
                    icon={icon}
                    mockup={mockup}
                    app_store={app_store}
                    play_store={play_store}
                    description={description}
                    is_hit={is_hit}
                    message="Амжилттай шинэчлэгдлээ"
                />
            </Dashboard>
        )
    }
}

export default withStyles(styles)(GameUpdate)
