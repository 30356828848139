import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import {
    ForgotPassword,
    Login,
    NotFound,
    PasswordReset,
    GameNew,
    Games,
    GameUpdate,
    Register,
    UserNew,
    Users,
    UserUpdate,
    Jobs,
    JobNew,
    JobUpdate,
} from './pages'
import { 
    SettingForm
} from './forms';

export default () => (
    <Router>
        <Switch>
            <Route
                path="/signin"
                component={Login}
            />
            <Route
                path="/signup"
                component={Register}
            />
            <Route
                path="/forgot/password"
                component={ForgotPassword}
            />
            <Route
                path="/password/reset/:token"
                component={PasswordReset}
            />
            <Route
                path="/"
                component={Users}
                exact
            />
            <Route
                path="/users/new"
                component={UserNew}
            />
            <Route
                path="/users/:id"
                component={UserUpdate}
            />
            <Route
                path="/users"
                component={Users}
            />
            <Route
                path="/games/new"
                component={GameNew}
            />
            <Route
                path="/games/:id"
                component={GameUpdate}
            />
            <Route
                path="/games"
                component={Games}
            />
            <Route 
                path="/jobs/new"
                component={JobNew}
            />
            <Route 
                path="/jobs/:id"
                component={JobUpdate}
            />
            <Route 
                path="/jobs"
                component={Jobs}
            />
            <Route
                path="/settings"
                component={SettingForm}
            />
            <Route component={NotFound} />
        </Switch>
    </Router>
)
