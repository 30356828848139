import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import {
    TextField,
    Button,
    Grid,
    Typography,
    Checkbox,
    FormControlLabel
} from '@material-ui/core'
import DropZone from '../components/DropZone'
import {
    CustomSnackBar,
} from '../components'
import axios from '../plugins/axios'

const styles = theme => ({
    marginBottom: {
        margin: '0 0 20px 0'
    }
})
class GameForm extends Component {

    static defaultProps = {
        name: '',
        cover: '',
        icon: '',
        mockup: '',
        app_store: '',
        play_store: '',
        description: '',
        is_hit: false,
    }

    constructor(props) {
        super(props)

        const {
            name,
            cover,
            icon,
            mockup,
            app_store,
            play_store,
            description,
            is_hit
        } = props

        this.state = {
            fetching: false,
            disabled: false,
            isSnackOpen: false,
            errors: {},
            name,
            cover,
            icon,
            mockup,
            app_store,
            play_store,
            description,
            is_hit
        }
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value
        })
    }

    reset = () => {
        this.setState({
            errors: {},
            name: '',
            cover: '',
            icon: '',
            mockup: '',
            app_store: '',
            play_store: '',
            description: '',
            is_hit: false
        })
    }

    storeOrUpdate = async e => {
        e.preventDefault()
        this.setState({
            disabled: true,
            errors: {}
        })

        const {
            path,
            method
        } = this.props

        const {
            name,
            cover,
            icon,
            mockup,
            app_store,
            play_store,
            description,
            is_hit
        } = this.state

        try {
            await axios[method](path, {
                name,
                cover,
                icon,
                mockup,
                app_store,
                play_store,
                description,
                is_hit
            })

            this.setState({
                isSnackOpen: true
            })

            if (method === 'post') {
                this.reset()
            }

            this.setState({
                disabled: false
            })
        } catch (err) {
            if (err.response.status === 422) {
                const { errors } = err.response.data
                this.setState({ errors })
            }
        }
    }

    snackClose = () => {
        this.setState({
            isSnackOpen: false
        })
    }

    onChange = name => (path = '') => {
        this.setState({
            [name]: path
        })
    }

    checkboxChanged = e => {
        const { checked } = e.target

        this.setState({
            is_hit: checked
        })
    }

    render() {
        const {
            name,
            cover,
            icon,
            mockup,
            app_store,
            play_store,
            description,
            is_hit,
            isSnackOpen,
            errors
        } = this.state

        const { marginBottom } = this.props.classes

        const { message } = this.props
        return (
            <div>

                <form
                    onSubmit={this.storeOrUpdate}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                error={!!errors.name}
                                helperText={errors.name}
                                label="Name"
                                value={name}
                                onChange={this.handleChange('name')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <div className={marginBottom}>
                                <Typography variant="subtitle1">Cover</Typography>
                                <DropZone
                                    uploadPath="img/upload"
                                    onChange={this.onChange('cover')}
                                    file={cover}
                                    error={!!errors.cover}
                                />
                            </div>
                            <div className={marginBottom}>
                                <Typography variant="subtitle1">Icon</Typography>
                                <DropZone
                                    uploadPath="img/upload"
                                    onChange={this.onChange('icon')}
                                    file={icon}
                                    error={!!errors.icon}
                                />
                            </div>
                            <div className={marginBottom}>
                                <Typography variant="subtitle1">Mockup</Typography>
                                <DropZone
                                    uploadPath="img/upload"
                                    onChange={this.onChange('mockup')}
                                    file={mockup}
                                    error={!!errors.mockup}
                                />
                            </div>
                            <TextField
                                error={!!errors.app_store}
                                helperText={errors.app_store}
                                label="Apple Store"
                                value={app_store}
                                onChange={this.handleChange('app_store')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.play_store}
                                helperText={errors.play_store}
                                label="Play Store"
                                value={play_store}
                                onChange={this.handleChange('play_store')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <TextField
                                error={!!errors.description}
                                helperText={errors.description}
                                label="Description"
                                value={description}
                                onChange={this.handleChange('description')}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={10}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={is_hit}
                                        onChange={this.checkboxChanged}
                                        value={is_hit}
                                        color="primary"
                                    />
                                }
                                label="Hit Game"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                    >
                        Хадгалах
                    </Button>
                    <CustomSnackBar
                        message={message}
                        open={isSnackOpen}
                        snackClose={this.snackClose}
                    />
                </form>
            </div >
        )
    }
}

export default withStyles(styles)(GameForm)
